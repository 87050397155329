/* eslint-disable-next-line */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { EnglishTranslations } from './en';
import { GermanTranslations } from './de';
export function Language() {
  return i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: EnglishTranslations
      },
      de: {
        translation: GermanTranslations
      }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
}
;
export default Language;
export var GermanTranslations = {
  'HINT_LOVE_COOKIES': 'We love cookies 🥦🍔🍕😉',
  'HINT_COOKIES_DESCRIPTION': 'We use cookies and similar technologies just like everybody else. By accepting you agree to this, as outlined in our',
  'HINT_COOKIE_POLICY': 'Cookie Policy.',
  'BTN_ACCEPT_ALL': 'Accept all',
  'BTN_ACCEPT_SELECTED': 'Accept selected',
  'HINT_ESSENTIALS': 'Essentials',
  'HINT_STATISTICS': 'Statistics',
  'HINT_SOCIAL': 'Social Media',
  'HINT_NO_APP_PLEASE_DOWNLOAD': 'Du hast unsere wiffme.app noch nicht. Bitte lade sie gleich herunter und werde Teil unserer Community.'
};